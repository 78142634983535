import {
  Button,
  Grid,
  IconButton,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import {
  CloudDownloadOutlined,
  HelpOutlineOutlined,
  FiberManualRecord,
} from "@mui/icons-material";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { BarChart } from "@mui/x-charts/BarChart";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const AudienceChart = ({ options }) => {
  const data2 = [
    { label: "Group A", value: 2400 },
    { label: "Group B", value: 4567 },
    { label: "Group C", value: 1398 },
    { label: "Group D", value: 9800 },
    { label: "Group E", value: 3908 },
    { label: "Group F", value: 4800 },
  ];

  const data1 = [
    { data: [35, 44, 24, 34] },
    { data: [51, 6, 49, 30] },
    { data: [15, 25, 30, 50] },
    { data: [60, 50, 15, 25] },
  ];

  const bar1Data = {
    barData: [
      { data: [35, 44, 24, 34] },
      { data: [51, 6, 49, 30] },
      { data: [15, 25, 30, 50] },
      { data: [60, 50, 15, 25] },
    ],
    progressBar: [
      { label: "Group A", value: 2400 },
      { label: "Group B", value: 4567 },
      { label: "Group C", value: 1398 },
      { label: "Group D", value: 9800 },
      { label: "Group E", value: 3908 },
      { label: "Group F", value: 4800 },
    ],
    barChartType: [{ data: ["Q1", "Q2", "Q3", "Q4"], scaleType: "band" }],
  };

  const demographData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceLanguagesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceInterestsPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceFollowersCountriesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceFollowersCitiesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceFollowersGenderAgeDistributionAgeRangeData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceFollowersGenderAgeDistributionPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceLikersData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceLikersCountriesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceLikersCitiesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceLikersGenderAgeDistributionAgeRangeData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceLikersGenderAgeDistributionPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceLikersLanguagesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceLikersInterestsValueData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceLanguagesData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceSubscribersTypesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceSubscribersCountriesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceSubscribersCitiesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceSubscribersGenderAgeDistributionAgeRangeData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceSubscribersGenderAgeDistributionPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceLikersData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceLikersCountriesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceLikersCitiesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceLikersGenderAgeDistributionAgeRangeData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceLikersGenderAgeDistributionPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceLikersLanguagesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceLikersInterestsValueData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceViewersData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceViewersCountriesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceViewersCitiesPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceViewersGenderAgeDistributionAgeRangeData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ChannelAudienceViewersGenderAgeDistributionPercentageData = {
    barData: [{ data: [35, 44, 15, 25] }, { data: [51, 6, 60, 50] }],
    progressBar: [
      { label: "USA", value: 2400 },
      { label: "India", value: 4567 },
      { label: "Canada", value: 1398 },
      { label: "Japan", value: 9800 },
      { label: "Germany", value: 3908 },
    ],
    barChartType: [
      { data: ["13 - 17", "18 - 24", "25 - 32", "33 - 45"], scaleType: "band" },
    ],
  };

  const ProfileAudienceLanguagesData = [
    { label: "United States = English", value: 2400 },
    { label: "Spain = Spanish", value: 4567 },
    { label: "China = Mandarin", value: 1398 },
    { label: "India = Hindi", value: 9800 },
    { label: "Brazil = Portuguese", value: 3908 },
    { label: "Russia = Russian", value: 4800 },
    { label: "Japan = Japanese", value: 3200 },
    { label: "Germany = German", value: 2100 },
    { label: "France = French", value: 1800 },
    { label: "Egypt = Arabic", value: 1500 },
  ];

  const ChannelAudienceLanguagesPercentageData = [
    { label: "United States = English", value: 2400 },
    { label: "Spain = Spanish", value: 4567 },
    { label: "China = Mandarin", value: 1398 },
    { label: "India = Hindi", value: 9800 },
    { label: "Brazil = Portuguese", value: 3908 },
    { label: "Russia = Russian", value: 4800 },
    { label: "Japan = Japanese", value: 3200 },
    { label: "Germany = German", value: 2100 },
    { label: "France = French", value: 1800 },
    { label: "Egypt = Arabic", value: 1500 },
  ];
  const ChannelAudienceViewersLanguagesData = [
    { label: "United States = English", value: 2400 },
    { label: "Spain = Spanish", value: 4567 },
    { label: "China = Mandarin", value: 1398 },
    { label: "India = Hindi", value: 9800 },
    { label: "Brazil = Portuguese", value: 3908 },
    { label: "Russia = Russian", value: 4800 },
    { label: "Japan = Japanese", value: 3200 },
    { label: "Germany = German", value: 2100 },
    { label: "France = French", value: 1800 },
    { label: "Egypt = Arabic", value: 1500 },
  ];

  const ProfileAudienceInterestsNameData = [
    {
      label: "Technology",
      value: 2500,
    },
    {
      label: "Sports",
      value: 1800,
    },
    {
      label: "Music",
      value: 1500,
    },
    {
      label: "Travel",
      value: 1200,
    },
    {
      label: "Food",
      value: 1000,
    },
    {
      label: "Fashion",
      value: 800,
    },
    {
      label: "Gaming",
      value: 500,
    },
    {
      label: "Politics",
      value: 300,
    },
    {
      label: "Education",
      value: 200,
    },
    {
      label: "Other",
      value: 100,
    },
  ];

  const ChannelAudienceInterestsNameData = [
    {
      label: "Technology",
      value: 2500,
    },
    {
      label: "Sports",
      value: 1800,
    },
    {
      label: "Music",
      value: 1500,
    },
    {
      label: "Travel",
      value: 1200,
    },
    {
      label: "Food",
      value: 1000,
    },
    {
      label: "Fashion",
      value: 800,
    },
    {
      label: "Gaming",
      value: 500,
    },
    {
      label: "Politics",
      value: 300,
    },
    {
      label: "Education",
      value: 200,
    },
    {
      label: "Other",
      value: 100,
    },
  ];

  const ProfileAudienceFollowerTypesNameData = [
    { label: "Mass followers", value: 2450 },
    { label: "Real", value: 9670 },
    { label: "Suspicious", value: 8150 },
    { label: "Influencers", value: 4797 },
  ];
  const ChannelAudienceSubscribersTypesNameData = [
    { label: "Mass followers", value: 2450 },
    { label: "Real", value: 9670 },
    { label: "Suspicious", value: 8150 },
    { label: "Influencers", value: 4797 },
  ];
  const ChannelAudienceViewersFollowersTypesNameData = [
    { label: "Mass followers", value: 2450 },
    { label: "Real", value: 9670 },
    { label: "Suspicious", value: 8150 },
    { label: "Influencers", value: 4797 },
  ];

  const ProfileAudienceFollowersCountriesData = [
    {
      label: "United States",
      value: 2509,
      color: "#4CAF50",
    },
    {
      label: "Canada",
      value: 1078,
      color: "#FF9800",
    },
    {
      label: "Mexico",
      value: 1256,
      color: "#2196F3",
    },
    {
      label: "United Kingdom",
      value: 1489,
      color: "#9C27B0",
    },
    {
      label: "Germany",
      value: 8567,
      color: "#03A9F4",
    },
    {
      label: "France",
      value: 6985,
      color: "#8BC34A",
    },
    {
      label: "Australia",
      value: 5900,
      color: "#FFC107",
    },
  ];
  const ChannelAudienceSubscribersCountriesData = [
    {
      label: "United States",
      value: 2509,
      color: "#4CAF50",
    },
    {
      label: "Canada",
      value: 1078,
      color: "#FF9800",
    },
    {
      label: "Mexico",
      value: 1256,
      color: "#2196F3",
    },
    {
      label: "United Kingdom",
      value: 1489,
      color: "#9C27B0",
    },
    {
      label: "Germany",
      value: 8567,
      color: "#03A9F4",
    },
    {
      label: "France",
      value: 6985,
      color: "#8BC34A",
    },
    {
      label: "Australia",
      value: 5900,
      color: "#FFC107",
    },
  ];
  const ChannelAudienceViewersCountriesData = [
    {
      label: "United States",
      value: 2509,
      color: "#4CAF50",
    },
    {
      label: "Canada",
      value: 1078,
      color: "#FF9800",
    },
    {
      label: "Mexico",
      value: 1256,
      color: "#2196F3",
    },
    {
      label: "United Kingdom",
      value: 1489,
      color: "#9C27B0",
    },
    {
      label: "Germany",
      value: 8567,
      color: "#03A9F4",
    },
    {
      label: "France",
      value: 6985,
      color: "#8BC34A",
    },
    {
      label: "Australia",
      value: 5900,
      color: "#FFC107",
    },
  ];

  const ProfileAudienceLikersCountriesData = [
    {
      label: "United States",
      value: 2509,
      color: "#4CAF50",
    },
    {
      label: "Canada",
      value: 1078,
      color: "#FF9800",
    },
    {
      label: "Mexico",
      value: 1256,
      color: "#2196F3",
    },
    {
      label: "United Kingdom",
      value: 1489,
      color: "#9C27B0",
    },
    {
      label: "Germany",
      value: 8567,
      color: "#03A9F4",
    },
    {
      label: "France",
      value: 6985,
      color: "#8BC34A",
    },
    {
      label: "Australia",
      value: 5900,
      color: "#FFC107",
    },
  ];
  const ChannelAudienceLikersCountriesData = [
    {
      label: "United States",
      value: 2509,
      color: "#4CAF50",
    },
    {
      label: "Canada",
      value: 1078,
      color: "#FF9800",
    },
    {
      label: "Mexico",
      value: 1256,
      color: "#2196F3",
    },
    {
      label: "United Kingdom",
      value: 1489,
      color: "#9C27B0",
    },
    {
      label: "Germany",
      value: 8567,
      color: "#03A9F4",
    },
    {
      label: "France",
      value: 6985,
      color: "#8BC34A",
    },
    {
      label: "Australia",
      value: 5900,
      color: "#FFC107",
    },
  ];

  const ProfileAudienceFollowersCitiesNameData = [
    {
      label: "New York",
      value: 1508,
    },
    {
      label: "Los Angeles",
      value: 1245,
    },
    {
      label: "Chicago",
      value: 1067,
    },
    {
      label: "Houston",
      value: 8432,
    },
    {
      label: "Phoenix",
      value: 7818,
    },
    {
      label: "Philadelphia",
      value: 6908,
    },
    {
      label: "San Antonio",
      value: 5123,
    },
  ];
  const ChannelAudienceSubscribersCitiesNameData = [
    {
      label: "New York",
      value: 1508,
    },
    {
      label: "Los Angeles",
      value: 1245,
    },
    {
      label: "Chicago",
      value: 1067,
    },
    {
      label: "Houston",
      value: 8432,
    },
    {
      label: "Phoenix",
      value: 7818,
    },
    {
      label: "Philadelphia",
      value: 6908,
    },
    {
      label: "San Antonio",
      value: 5123,
    },
  ];

  const ProfileAudienceLikersCitiesData = [
    {
      label: "New York",
      value: 1508,
    },
    {
      label: "Los Angeles",
      value: 1245,
    },
    {
      label: "Chicago",
      value: 1067,
    },
    {
      label: "Houston",
      value: 8432,
    },
    {
      label: "Phoenix",
      value: 7818,
    },
    {
      label: "Philadelphia",
      value: 6908,
    },
    {
      label: "San Antonio",
      value: 5123,
    },
  ];
  const ChannelAudienceLikersCitiesData = [
    {
      label: "New York",
      value: 1508,
    },
    {
      label: "Los Angeles",
      value: 1245,
    },
    {
      label: "Chicago",
      value: 1067,
    },
    {
      label: "Houston",
      value: 8432,
    },
    {
      label: "Phoenix",
      value: 7818,
    },
    {
      label: "Philadelphia",
      value: 6908,
    },
    {
      label: "San Antonio",
      value: 5123,
    },
  ];
  const ChannelAudienceViewersCitiesNameData = [
    {
      label: "New York",
      value: 1508,
    },
    {
      label: "Los Angeles",
      value: 1245,
    },
    {
      label: "Chicago",
      value: 1067,
    },
    {
      label: "Houston",
      value: 8432,
    },
    {
      label: "Phoenix",
      value: 7818,
    },
    {
      label: "Philadelphia",
      value: 6908,
    },
    {
      label: "San Antonio",
      value: 5123,
    },
  ];

  const ProfileAudienceLikersLanguagesData = [
    { label: "Hindi", value: 5678 },
    { label: "English", value: 6785 },
    { label: "Japanese", value: 8235 },
    { label: "Sanskrit", value: 1235 },
  ];
  const ChannelAudienceLikersLanguagesData = [
    { label: "Hindi", value: 5678 },
    { label: "English", value: 6785 },
    { label: "Japanese", value: 8235 },
    { label: "Sanskrit", value: 1235 },
  ];
  const ChannelAudienceViewersLanguagesPercentageData = [
    { label: "Hindi", value: 5678 },
    { label: "English", value: 6785 },
    { label: "Japanese", value: 8235 },
    { label: "Sanskrit", value: 1235 },
  ];

  const ProfileAudienceLikersInterestsNameData = [
    { label: "ESports", value: 8765 },
    { label: "Cooking", value: 6235 },
    { label: "Education", value: 7235 },
    { label: "Entertainment", value: 8935 },
  ];
  const ChannelAudienceLikersInterestsNameData = [
    { label: "ESports", value: 8765 },
    { label: "Cooking", value: 6235 },
    { label: "Education", value: 7235 },
    { label: "Entertainment", value: 8935 },
  ];
  const ChannelAudienceViewersInterestsNameData = [
    { label: "ESports", value: 8765 },
    { label: "Cooking", value: 6235 },
    { label: "Education", value: 7235 },
    { label: "Entertainment", value: 8935 },
  ];

  const ProfileAudienceLikersFollowersTypesNameData = [
    { label: "ESports", value: 8765 },
    { label: "Cooking", value: 6235 },
    { label: "Education", value: 7235 },
    { label: "Entertainment", value: 8935 },
  ];
  const ChannelAudienceLikersFollowersTypesNameData = [
    { label: "ESports", value: 8765 },
    { label: "Cooking", value: 6235 },
    { label: "Education", value: 7235 },
    { label: "Entertainment", value: 8935 },
  ];

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#e36e46" : "#308fe8",
    },
  }));

  function CustomizedProgressBars({ data }) {
    return (
      <Stack spacing={2} sx={{ flexGrow: 1, padding: "10px 40px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>{data.label}</Typography>
          <Typography>{data.value / 100} %</Typography>
        </Box>
        <BorderLinearProgress variant="determinate" value={data.value / 100} />
      </Stack>
    );
  }
  function Bar1({ barData1 }) {
    return (
      <>
        <BarChart
          series={barData1.barData}
          height={250}
          xAxis={barData1.barChartType}
          margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
        />
        {barData1.progressBar.map((data) => {
          return <CustomizedProgressBars data={data} />;
        })}
      </>
    );
  }
  function Bar2({ barData2 }) {
    return (
      <>
        {barData2.map((data) => {
          return <CustomizedProgressBars data={data} />;
        })}
      </>
    );
  }
  function PieChart1({ pieData }) {
    return (
      <Box>
        <PieChart
          series={[
            {
              data: pieData,
              cx: "60%",
              cy: "50%",
              innerRadius: 60,
              outerRadius: 120,
              color: [
                "#FF69B4",
                "#33CC33",
                "#6666FF",
                "#CCCCCC",
                "#FF9900",
                "#111",
              ],
            },
          ]}
          height={300}
          slotProps={{
            legend: { hidden: true },
          }}
        />
        {data2.map((data) => {
          return <CustomizedProgressBars data={data} />;
        })}
      </Box>
    );
  }

  function DemographicGraph({ graphData }) {
    return (
      <>
        <BarChart
          slotProps={{
            legend: { hidden: true },
          }}
          series={graphData.barData}
          height={250}
          xAxis={graphData.barChartType}
          margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
        />
        <Box sx={{ marginTop: 2 }}>
          {graphData.progressBar.map((data) => {
            return <CustomizedProgressBars data={data} />;
          })}
        </Box>
      </>
    );
  }

  return (
    <>
      {options === "Profile Audience Languages Percentage" ? (
        <DemographicGraph graphData={ProfileAudienceLanguagesPercentageData} />
      ) : options === "Profile Audience Interests Percentage" ? (
        <DemographicGraph graphData={ProfileAudienceInterestsPercentageData} />
      ) : options === "Profile Audience Likers Languages Percentage" ? (
        <DemographicGraph
          graphData={ProfileAudienceLikersLanguagesPercentageData}
        />
      ) : options ===
        "Profile Audience Followers Gender Age Distribution - Age Range" ? (
        <DemographicGraph
          graphData={ProfileAudienceFollowersGenderAgeDistributionAgeRangeData}
        />
      ) : options === "Profile Audience Likers Interests Value" ? (
        <DemographicGraph graphData={ProfileAudienceLikersInterestsValueData} />
      ) : options ===
        "Profile Audience Likers Gender Age Distribution - Age Range" ? (
        <DemographicGraph
          graphData={ProfileAudienceLikersGenderAgeDistributionAgeRangeData}
        />
      ) : options ===
        "Channel Audience Subscribers Gender Age Distribution - Age Range" ? (
        <DemographicGraph
          graphData={
            ChannelAudienceSubscribersGenderAgeDistributionAgeRangeData
          }
        />
      ) : options ===
        "Channel Audience Likers Gender Age Distribution - Age Range" ? (
        <DemographicGraph
          graphData={ChannelAudienceLikersGenderAgeDistributionAgeRangeData}
        />
      ) : options === "Channel Audience Likers Languages Percentage" ? (
        <DemographicGraph
          graphData={ChannelAudienceLikersLanguagesPercentageData}
        />
      ) : options === "Channel Audience Likers Interests Value" ? (
        <DemographicGraph graphData={ChannelAudienceLikersInterestsValueData} />
      ) : options ===
        "Channel Audience Viewers Gender Age Distribution - Age Range" ? (
        <DemographicGraph
          graphData={ChannelAudienceViewersGenderAgeDistributionAgeRangeData}
        />
      ) : options === "Profile Audience Followers Countries" ? (
        <Bar2 barData2={ProfileAudienceFollowersCountriesData} />
      ) : options === "Profile Audience Languages" ? (
        <Bar2 barData2={ProfileAudienceLanguagesData} />
      ) : options === "Profile Audience Interests Name" ? (
        <Bar2 barData2={ProfileAudienceInterestsNameData} />
      ) : options === "Profile Audience Follower Types Name" ? (
        <Bar2 barData2={ProfileAudienceFollowerTypesNameData} />
      ) : options === "Profile Audience Followers Cities Name" ? (
        <Bar2 barData2={ProfileAudienceFollowersCitiesNameData} />
      ) : options === "Profile Audience Likers Countries" ? (
        <Bar2 barData2={ProfileAudienceLikersCountriesData} />
      ) : options === "Profile Audience Likers Cities Name" ? (
        <Bar2 barData2={ProfileAudienceLikersCitiesData} />
      ) : options === "Profile Audience Likers Languages" ? (
        <Bar2 barData2={ProfileAudienceLikersLanguagesData} />
      ) : options === "Profile Audience Likers Interests Name" ? (
        <Bar2 barData2={ProfileAudienceLikersInterestsNameData} />
      ) : options === "Profile Audience Likers Followers Types Name" ? (
        <Bar2 barData2={ProfileAudienceLikersFollowersTypesNameData} />
      ) : options === "Channel Audience Languages Percentage" ? (
        <Bar2 barData2={ChannelAudienceLanguagesPercentageData} />
      ) : options === "Channel Audience Interests Name" ? (
        <Bar2 barData2={ChannelAudienceInterestsNameData} />
      ) : options === "Channel Audience Interests Percentage" ? (
        <Bar2 barData2={ChannelAudienceInterestsNameData} />
      ) : options === "Channel Audience Subscribers Types Name" ? (
        <Bar2 barData2={ChannelAudienceSubscribersTypesNameData} />
      ) : options === "Channel Audience Subscribers Countries" ? (
        <Bar2 barData2={ChannelAudienceSubscribersCountriesData} />
      ) : options === "Channel Audience Subscribers Cities Name" ? (
        <Bar2 barData2={ChannelAudienceSubscribersCitiesNameData} />
      ) : options === "Channel Audience Likers Countries" ? (
        <Bar2 barData2={ChannelAudienceLikersCountriesData} />
      ) : options === "Channel Audience Likers Cities Name" ? (
        <Bar2 barData2={ChannelAudienceLikersCitiesData} />
      ) : options === "Channel Audience Likers Languages" ? (
        <Bar2 barData2={ChannelAudienceLikersLanguagesData} />
      ) : options === "Channel Audience Likers Interests Name" ? (
        <Bar2 barData2={ChannelAudienceLikersInterestsNameData} />
      ) : options === "Channel Audience Likers Followers Types Name" ? (
        <Bar2 barData2={ChannelAudienceLikersFollowersTypesNameData} />
      ) : options === "Channel Audience Viewers Countries" ? (
        <Bar2 barData2={ChannelAudienceViewersCountriesData} />
      ) : options === "Channel Audience Viewers Cities Name" ? (
        <Bar2 barData2={ChannelAudienceViewersCitiesNameData} />
      ) : options === "Channel Audience Viewers Languages" ? (
        <Bar2 barData2={ChannelAudienceViewersLanguagesData} />
      ) : options === "Channel Audience Viewers Languages Percentage" ? (
        <Bar2 barData2={ChannelAudienceViewersLanguagesPercentageData} />
      ) : options === "Channel Audience Viewers Interests Name" ? (
        <Bar2 barData2={ChannelAudienceInterestsNameData} />
      ) : options === "Channel Audience Viewers Followers Types Name" ? (
        <Bar2 barData2={ChannelAudienceViewersInterestsNameData} />
      ) : options === "Profile Audience Follower Types Percentage" ||
        options ===
          "Profile Audience Followers Gender Age Distribution - Gender" ||
        options ===
          "Profile Audience Likers Gender Age Distribution - Gender" ||
        options === "Profile Audience Likers Followers Types Percentage" ||
        options ===
          "Channel Audience Subscribers Gender Age Distribution - Gender" ||
        options ===
          "Channel Audience Likers Gender Age Distribution - Gender" ||
        options === "Channel Audience Likers Followers Types Percentage" ||
        options ===
          "Channel Audience Viewers Gender Age Distribution - Gender" ||
        options === "Channel Audience Viewers Interests Value" ||
        options === "Channel Audience Viewers Followers Types Percentage" ? (
        <PieChart1 pieData={data2} />
      ) : options === "Profile Audience Followers Countries Percentage" ||
        options === "Profile Audience Followers Cities Percentage" ||
        options ===
          "Profile Audience Followers Gender Age Distribution Percentage" ||
        options === "Profile Audience Likers Countries Percentage" ||
        options === "Profile Audience Likers Cities Percentage" ||
        options === "Profile Audience Likers" ||
        options ===
          "Profile Audience Likers Gender Age Distribution Percentage" ||
        options === "Channel Audience Languages" ||
        options === "Channel Audience Subscribers Types Percentage" ||
        options === "Channel Audience Subscribers Countries Percentage" ||
        options === "Channel Audience Subscribers Cities Percentage" ||
        options ===
          "Channel Audience Subscribers Gender Age Distribution Percentage" ||
        options === "Channel Audience Likers" ||
        options === "Channel Audience Likers Countries Percentage" ||
        options === "Channel Audience Likers Cities Percentage" ||
        options ===
          "Channel Audience Likers Gender Age Distribution Percentage" ||
        options === "Channel Audience Viewers" ||
        options === "Channel Audience Viewers Countries Percentage" ||
        options === "Channel Audience Viewers Cities Percentage" ||
        options ===
          "Channel Audience Viewers Gender Age Distribution Percentage" ? (
        <DemographicGraph graphData={demographData} />
      ) : (
        <>{options}</>
      )}
    </>
  );
};

export default AudienceChart;
