import {
    FormControl,
    Divider,
    Select,
    MenuItem,
    Slider,
    TextField,
    Box,
    styled,
    IconButton,
    Tooltip,
    Tabs,
    Tab,
} from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";

export default function FiterAccordian({
    component,
    tooltipInfo,
    text,
}) {
    return (
        <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: 1,
                    }}
                >
                    <IconButton style={{ color: "#3DBE72" }}>
                        <Tooltip title={tooltipInfo}>
                            <HelpOutlineRoundedIcon
                                style={{ fontSize: 12, padding: 0, margin: 0 }}
                            />
                        </Tooltip>
                    </IconButton>
                    <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {component}
            </AccordionDetails>
        </Accordion>
    )
}
