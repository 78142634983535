import axios from "axios";

// const URL = "https://grapsbackend.tdpvista.co.in/api/v1";
const URL = process.env.REACT_APP_BASE_URL;

export const registerUser = async (data) => {
  try {
    const response = await axios.post(`${URL}/register`, data);

    console.log(response);

    const token = response.data.token;

    localStorage.setItem("authToken", token);

    return response;
  } catch (error) {
    console.log("error while signing up: " + error);
  }
};

export const checkEmail = async (myemail) => {
  const email = {
    email: myemail,
  };
  try {
    const response = await axios.post(`${URL}/userifexists`, email);
    return response;
  } catch (e) {
    console.error("Occur error while checking the email");
    return false;
  }
};

export const loginUser = async (data) => {
  try {
    const response = await axios.post(`${URL}/login`, data);
    console.log(response);

    if (response.status === 200) {
      const token = response.data.authorisation.token;
      localStorage.setItem("authToken", token);
      return response;
    } else if (response.data.status == 401) {
      return response;
    }
    
  } catch (error) {
    console.error("Error while login: " + error.message);
  }
};

export const registerBrand = async (brandData) => {
  try {
    const response = await axios.post(`${URL}/registeredbrand`, brandData);
    console.log(response);

    // Customize the logic based on the expected response from the API
    if (response.data.status === 201) {
      // Handle success scenario, if needed
      return response;
    }
    // Handle other status codes if needed
  } catch (error) {
    console.error("Error while registering brand: " + error.message);
    // Consider throwing the error or returning a more structured response
  }
};

export const registerEmployee = async (empData) => {
  try {
    const response = await axios.post(`${URL}/registeremployee`, empData);
    console.log(response);

    // Customize the logic based on the expected response from the API
    if (response.data.status === 201) {
      // Handle success scenario, if needed
      return response;
    }
    // Handle other status codes if needed
  } catch (error) {
    console.error("Error while registering employee: " + error.message);
    // Consider throwing the error or returning a more structured response
  }
};

export const sendEmail = async (data) => {
  try {
    const response = await axios.post(`${URL}/email/send`, data);
    console.log(response);

    return response;

    // Handle other status codes if needed
  } catch (error) {
    console.error(error);
    // Consider throwing the error or returning a more structured response
  }
};

export const getApprovedStatus = async ({ id, inf_val, status }) => {
  try {
    const response = await axios.post(
      `${URL}/collection/influencer/update/status/get`,
      {
        id,
        inf_val,
        status,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    // console.error("Error while getting approved status: " + error);
  }
};
export const addNewColumn = async (data) => {
  try {
    const response = await axios.post(`${URL}/customcolumn/head/add`, data);
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    // console.error("Error while getting approved status: " + error);
  }
};
export const addNewColumnData = async (data) => {
  try {
    const response = await axios.post(`${URL}/customcolumn/cell/add`, data);
    console.log("response/customcolumn/cell/add", response);
    return response;
  } catch (error) {
    console.log(error);
    // console.error("Error while getting approved status: " + error);
  }
};
export const getColumnHead = async () => {
  try {
    const response = await axios.post(`${URL}/customcolumn/head/get`);
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    // console.error("Error while getting approved status: " + error);
  }
};
export const updateColumnHead = async (data) => {
  try {
    const response = await axios.post(`${URL}/customcolumn/head/update`, data);
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    // console.error("Error while getting approved status: " + error);
  }
};

export const shareCollection = async (data) => {
  try {
    const response = await axios.post(`${URL}/shareaccess/create`, data.data);
    console.log(response);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getCollectionShareAcces = async (data) => {
  try {
    console.log("daata", data);
    const response = await axios.post(`${URL}/shareaccess/get`, data);
    console.log("responseresponse", response);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateCollectionShareAcces = async (data) => {
  try {
    const response = await axios.post(`${URL}/shareaccess/update`, data);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const genConfirmInstaOTP = async (id) => {
  try {
    const response = await axios.post(`${URL}/generateotpinstagram`, id);
    return response;
  } catch (error) {
    console.error(
      "Error while generating otp for insta verification : " + error.message
    );
  }
};

// export const genConfirmYoutubeOTP = async (id)  => {
//     try{
//         const response = await axios.post(`${URL}/generateotpinstagram`, id)
//         return response;
//     }
//     catch (error) {
//         console.error("Error while generating otp for insta verification : " + error.message);
//     }
// }
