import { useState } from "react";
import {
  Typography,
  Grid,
  Box,
  Icon,
  IconButton,
  Button,
  Tooltip,
  Dialog,
  styled,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  InputAdornment,
  Divider,
  AvatarGroup,
  Avatar,
  Menu,
  MenuItem,
  ClickAwayListener,
} from "@mui/material";

// import { Box } from '@mui/system'

import DP from "../../../images/dp-lg.png";
import dp from "../../../images/dp.png";
import insta from "../../../images/insta-xsm.png";
import insta_sm from "../../../images/insta-sm.png";
import youtube_sm from "../../../images/youtube-sm.png";
import whatsapp from "../../../images/WhatsApp.png";
import gmail from "../../../images/Gmail.png";
import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SearchIcon from "@mui/icons-material/Search";
// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import { Button } from '@mui/base';
import AccountAnalytics from "./AccountAnalytics";
import { Link } from "react-router-dom";
import { addInfluencerToCollection } from "../../../../service/collections api/addInfluencerToCollection";

import ShareProfile from "./ShareProfile";

const TemptDP =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy_32Pr0SWJNqgaFj2ILPdMaE_j2DBZcl4ow&usqp=CAU";

const InfluencerProfile = ({ close, row, selectedSocial }) => {
  const baseURL = window.location.origin;
  // console.log(window.location.origin);

  const [showDp, setShowDp] = useState(false);
  const [sharePopup, setSharePopup] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showCampaign, setShowCampaign] = useState(false);
  const [showCollection, setShowCollection] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const {
    id,
    name,
    categories,
    followers,
    engagement,
    average,
    likes,
    comments,
    actions,
    photo,
  } = row;

  // Encryption
  // Base64 encode function
  const encodeId = (id) => btoa(id);
  const encodedId = btoa(id.toString());

  const handleClickDP = () => {
    setShowDp(true);
  };

  const handleShowCampaign = () => {
    setShowCampaign(true);
  };
  const handleClose = () => {
    setShowCampaign(false);
  };

  const showSharepopup = () => {
    setSharePopup(true);
  };
  const closeSharepopup = () => {
    setSharePopup(false);
  };
  //addInfluencer to collectin :
  const addInfluencer = async () => {
    try {
      const response = await addInfluencerToCollection({
        id: 1,
        influencer_id: id,
      });
      console.log(response);
      setIsAdded(true);
    } catch (e) {
      console.error("error while adding influencer  : " + e.message);
    }
  };

  // dropdown logics
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* share profile  */}
      <Dialog
        open={sharePopup}
        onClose={closeSharepopup}
        PaperProps={{
          style: { borderRadius: "18px", border: "1px solid black" },
        }}
      >
        <ShareProfile
          close={closeSharepopup}
          name={name}
          id={encodedId}
          baseUrl={baseURL}
        />
      </Dialog>

      {/* Campaign Popup  */}
      <Dialog
        open={showCampaign}
        onClose={() => setShowCampaign(false)}
        style={{ borderRadius: 20 }}
        maxWidth="md"
      >
        <Grid container>
          <Grid
            item
            md={4}
            p={3}
            justifyContent="space-between"
            style={{ background: "rgba(61, 190, 114, 0.2)" }}
          >
            <Grid container>
              <Grid item md={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Campaign
                </Typography>
                <TextField
                  size="small"
                  placeholder="Search here..."
                  sx={{
                    borderRadius: 8, // Adjust the value as needed for your desired border radius
                    background: "#FFFFFF",
                    marginTop: 2,
                    marginBottom: 5,
                    boxShadow: "12px 9px 24px -11px rgba(61,190,114,1)",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 8, // Adjust the value as needed for your desired border radius
                      paddingRight: 0.4,
                      "&:hover fieldset": {
                        borderColor: "rgba(61, 190, 114, 1)", // Set the border color for hover state
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(61, 190, 114, 1)", // Set the border color for focused state
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <nav aria-label="secondary mailbox folders">
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          borderRadius: "10px",
                          background: "black",
                          color: "#FFFFFF",
                          "&:hover": { background: "black", color: "#FFFFFF" },
                        }}
                      >
                        <ListItemText>
                          <span>Kurkure</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ borderRadius: "10px" }}
                        component="a"
                        href="#simple-list"
                      >
                        <ListItemText>
                          <span>Lays</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ borderRadius: "10px" }}
                        component="a"
                        href="#simple-list"
                      >
                        <ListItemText>
                          <span>Pringles</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </nav>
              </Grid>
            </Grid>
            <Grid item md={12} mt={12}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                style={{
                  background: "rgba(61, 190, 114, 0.3)",
                  borderRadius: 20,
                  textTransform: "none",
                  boxShadow: "none",
                  padding: "5px 20px",
                  border: "2px solid rgba(61, 190, 114, 1)",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Create new Campaign
              </Button>
            </Grid>
          </Grid>

          <Grid item md={8}>
            <Grid item md={12} align="end" p={2}>
              <Tooltip title="Close" arrow placement="left">
                <IconButton onClick={() => setShowCampaign(false)}>
                  <CancelOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid container p={4}>
              <Grid item md={12} display="flex">
                <img
                  width={60}
                  style={{ borderRadius: "50%" }}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT614EiE7SOojHkzZp69vJVoo9awHcCIXgS4A&usqp=CAU"
                  alt=""
                />
                <Box ml={2}>
                  <Typography fontSize="1.3rem" fontWeight="bold">
                    Kurkure
                  </Typography>
                  <Typography fontSize="0.9rem">
                    We want to increase our yearly sales.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} mt={3}>
                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                  Deliverables
                </Typography>
                <Box>
                  <Button
                    style={{
                      textTransform: "none",
                      color: "#3DBE72",
                      borderColor: "#3DBE72",
                    }}
                    size="small"
                    variant="outlined"
                    startIcon={<img src={insta} />}
                  >
                    Instagram Reels
                  </Button>
                  <Button
                    style={{
                      textTransform: "none",
                      color: "#3DBE72",
                      borderColor: "#3DBE72",
                      margin: "0px 10px",
                    }}
                    size="small"
                    variant="outlined"
                    startIcon={<img src={insta} />}
                  >
                    Instagram Posts
                  </Button>
                  <Button
                    style={{
                      textTransform: "none",
                      color: "#3DBE72",
                      borderColor: "#3DBE72",
                    }}
                    size="small"
                    variant="outlined"
                    startIcon={<img src={insta} />}
                  >
                    Instagram Story
                  </Button>
                </Box>
              </Grid>

              <Grid item md={12} mt={3}>
                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                  Creator Preferences
                </Typography>
                <Box>
                  <Button
                    style={{
                      textTransform: "none",
                      color: "#3DBE72",
                      borderColor: "#3DBE72",
                    }}
                    size="small"
                    variant="outlined"
                  >
                    Male
                  </Button>
                  <Button
                    style={{
                      textTransform: "none",
                      color: "#3DBE72",
                      borderColor: "#3DBE72",
                      margin: "0px 5px",
                    }}
                    size="small"
                    variant="outlined"
                  >
                    Female
                  </Button>
                  <Button
                    style={{
                      textTransform: "none",
                      color: "#3DBE72",
                      borderColor: "#3DBE72",
                    }}
                    size="small"
                    variant="outlined"
                  >
                    Both
                  </Button>
                </Box>
              </Grid>

              <Grid item md={12} mt={3}>
                <Typography fontWeight={"bold"}>Campaign Budget</Typography>
                <Typography fontSize={"1.5rem"} fontWeight={"bold"}>
                  $1.5k
                </Typography>
              </Grid>
              <Grid item md={12} align="end">
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    background: "#3DBE72",
                    borderRadius: 20,
                    textTransform: "none",
                    boxShadow: "none",
                    padding: "5px 20px",
                  }}
                >
                  Add to Campaign
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>

      {/* Collection Popup  */}
      <Dialog
        open={showCollection}
        onClose={() => setShowCollection(false)}
        style={{ borderRadius: 20 }}
        maxWidth="md"
      >
        <Grid container>
          <Grid
            item
            md={4}
            p={3}
            justifyContent="space-between"
            style={{ background: "rgba(61, 190, 114, 0.2)" }}
          >
            <Grid container>
              <Grid item md={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Collection
                </Typography>
                <TextField
                  size="small"
                  placeholder="Search here..."
                  sx={{
                    borderRadius: 8, // Adjust the value as needed for your desired border radius
                    background: "#FFFFFF",
                    marginTop: 2,
                    marginBottom: 5,
                    boxShadow: "12px 9px 24px -11px rgba(61,190,114,1)",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 8, // Adjust the value as needed for your desired border radius
                      paddingRight: 0.4,
                      "&:hover fieldset": {
                        borderColor: "rgba(61, 190, 114, 1)", // Set the border color for hover state
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(61, 190, 114, 1)", // Set the border color for focused state
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <nav aria-label="secondary mailbox folders">
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText>
                          <span>Food Influencers</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ borderRadius: "10px" }}
                        component="a"
                        href="#simple-list"
                      >
                        <ListItemText>
                          <span>Lays</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ borderRadius: "10px" }}
                        component="a"
                        href="#simple-list"
                      >
                        <ListItemText>
                          <span>Pringles</span>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </nav>
              </Grid>
            </Grid>
            <Grid item md={12} mt={12}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                style={{
                  background: "rgba(61, 190, 114, 0.3)",
                  borderRadius: 20,
                  textTransform: "none",
                  boxShadow: "none",
                  padding: "5px 20px",
                  border: "2px solid rgba(61, 190, 114, 1)",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Create new Collection
              </Button>
            </Grid>
          </Grid>

          <Grid item md={8}>
            <Grid item md={12} align="end" p={2}>
              <Tooltip title="Close" arrow placement="left">
                <IconButton onClick={() => setShowCollection(false)}>
                  <CancelOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid container p={4}>
              <Grid item md={12} display="flex">
                <AvatarGroup
                  max={4}
                  total={147}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{ width: 80, height: 80 }}
                    alt="Remy Sharp"
                    src={TemptDP}
                  />
                  <Avatar
                    sx={{ width: 60, height: 60 }}
                    alt="Travis Howard"
                    src={TemptDP}
                  />
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt="Agnes Walker"
                    src={TemptDP}
                  />
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    alt="Trevor Henderson"
                    src={TemptDP}
                  />
                </AvatarGroup>
                <Box ml={2}>
                  <Typography fontSize="1.3rem" fontWeight="bold">
                    Food Influencers
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} mt={2}>
                <Button
                  style={{
                    textTransform: "none",
                    color: "#3DBE72",
                    borderColor: "#3DBE72",
                    marginRight: 10,
                  }}
                  size="small"
                  variant="outlined"
                >
                  Food & Drinks
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    color: "#3DBE72",
                    borderColor: "#3DBE72",
                  }}
                  size="small"
                  variant="outlined"
                >
                  Infotainment
                </Button>
              </Grid>
              <Grid item md={12} mt={3}>
                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                  Assigned to Campaign
                </Typography>
                <Typography>Lays Marketing</Typography>
              </Grid>

              <Grid item md={12} mt={3}>
                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                  Bio
                </Typography>
                <Typography>
                  Live life on your own tems #jointhemvmt Watches, Eyewear &
                  Premium Accessories For her @mvmforher Shop our Site
                </Typography>
                <span>
                  #Live #life #jointhemvmt #Watches #Eyewear #Premium
                  #Accessories
                </span>
              </Grid>

              <Grid item md={12} align="end">
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    background: "#3DBE72",
                    borderRadius: 20,
                    textTransform: "none",
                    boxShadow: "none",
                    padding: "5px 20px",
                  }}
                  onClick={() => addInfluencer()}
                >
                  {isAdded ? "Added to Collection" : "Add to Collection"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>

      <Box style={{ padding: 20 }}>
        <Dialog open={showDp}>
          <img src={photo} alt="" />

          <IconButton size="small" onClick={() => setShowDp(false)}>
            <Tooltip
              title="Close"
              arrow
              componentsProps={{
                arrow: {
                  sx: {
                    //   backgroundColor: 'red',
                    color: "red",
                  },
                },
                tooltip: {
                  sx: {
                    color: "white",
                    backgroundColor: "red",
                    // fontSize: "0.9rem",
                  },
                },
              }}
            >
              <CancelOutlinedIcon />
            </Tooltip>
          </IconButton>
        </Dialog>
        <Tooltip
          title="close"
          arrow
          componentsProps={{
            arrow: {
              sx: {
                //   backgroundColor: 'red',
                color: "red",
              },
            },
            tooltip: {
              sx: {
                color: "white",
                backgroundColor: "red",
                // fontSize: "0.9rem",
              },
            },
          }}
        >
          <IconButton
            onClick={() => close(false)}
            style={{ position: "absolute", right: 20, padding: 0 }}
          >
            <CloseRoundedIcon style={{ padding: 0 }} />
          </IconButton>
        </Tooltip>
        <Typography
          style={{
            color: "#3DBE72",
            fontWeight: "bold",
            fontSize: "1.6rem",
            marginBottom: "1.5rem",
          }}
        >
          Find Influencer
        </Typography>
        <Grid container sx={{ marginTop: "30px" }}>
          <Grid item md={2} xs={3}>
            <Box
              position="relative"
              onMouseEnter={() => setShowOverlay(true)}
              onMouseLeave={() => setShowOverlay(false)}
              onClick={handleClickDP}
              style={{ cursor: "pointer" }}
            >
              <img
                src={
                  photo
                    ? photo
                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU"
                }
                alt=""
                width="100%"
                height="150px"
                style={{
                  borderRadius: "50%",
                  objectFit: "fill",
                  margin: "auto 0",
                }}
              />
              {showOverlay && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "40%",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    // borderRadius: '50%',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                  }}
                >
                  {/* Your overlay content goes here */}
                  <span
                    style={{
                      color: "green",
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                    }}
                  >
                    View
                  </span>
                </div>
              )}
            </Box>
          </Grid>
          <Grid
            item
            md={10}
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "20px",
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box>
                <Link
                  to={`/dashboard/find-influencers/profile/${encodedId}`}
                  target="_blank"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography
                    style={{ fontWeight: "bold", fontSize: "1.2rem" }}
                  >
                    {name}
                  </Typography>
                  <Typography style={{ fontSize: 10, color: "#7b7b7b" }}>
                    {"10M"} total audience (India)
                  </Typography>
                </Link>
              </Box>
              {/* <Box style={{ marginLeft: 10 }}>
                <IconButton
                  style={{
                    color: "#3DBE72",
                    padding: 5,
                    border: "1px solid #3DBE72",
                  }}
                  aria-label="add to shopping cart"
                >
                  <TurnRightRoundedIcon style={{ fontSize: 29 }} />
                </IconButton>
              </Box> */}
            </Box>
            <Box style={{ marginTop: 10 }}>
              {/* <Button
                onClick={() => setShowCampaign(true)}
                variant="outlined"
                style={{
                  padding: "5px 20px",
                  borderRadius: 20,
                  fontSize: "11px",
                  color: "#3DBE72",
                  fontWeight: "bold",
                  textTransform: "none",
                  marginRight: "10px",
                  "&:hover": { borderColor: "#3DBE72" },
                }}
              >
                Add to Campaign
              </Button> */}

              <Button
                onClick={showSharepopup}
                size="small"
                variant="contained"
                style={{
                  borderRadius: 20,
                  fontSize: "11px",
                  color: "white",
                  fontWeight: "bold",
                  background: "#3DBE72",
                  textTransform: "none",
                  boxShadow: "none",
                }}
              >
                Share
              </Button>

              {/* <IconButton
                onClick={() => setShowCollection(true)}
                style={{ color: "#3DBE72" }}
                aria-label="add to shopping cart"
              >
                <AddCircleRoundedIcon style={{ padding: 0, fontSize: 25 }} />
              </IconButton> */}
              <Button>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <IconButton
                      onClick={handleClick}
                      style={{ color: "#3DBE72" }}
                      aria-label="add to shopping cart"
                    >
                      <AddCircleRoundedIcon
                        style={{ padding: 0, fontSize: 25 }}
                      />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose1}
                      style={{ padding: "5px" }}
                    >
                      {[
                        <MenuItem
                          onClick={() => setShowCampaign(true)}
                          style={{
                            padding: "5px 20px",
                            // borderRadius: 20,
                            // fontSize: "11px",
                            color: "#3DBE72",
                            fontWeight: "bold",
                            textTransform: "none",
                            margin: "0 5px",
                          }}
                        >
                          Add to Campaign
                        </MenuItem>,
                        <MenuItem
                          onClick={() => setShowCollection(true)}
                          style={{
                            padding: "5px 20px",
                            // borderRadius: 20,
                            // fontSize: "11px",
                            color: "#3DBE72",
                            fontWeight: "bold",
                            textTransform: "none",
                            margin: "0 5px",
                          }}
                        >
                          Add to Collection
                        </MenuItem>,
                      ]}
                    </Menu>
                  </div>
                </ClickAwayListener>
              </Button>
            </Box>
            <Box>
              <IconButton size="large">
                <img src={insta_sm} alt="" width={25} />
              </IconButton>
              <IconButton size="large">
                <img src={youtube_sm} alt="" width={28} />
              </IconButton>
              <IconButton size="large">
                <img
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png"
                  }
                  alt=""
                  width={25}
                />
              </IconButton>
              <IconButton size="large">
                <img
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png"
                  }
                  alt=""
                  width={27}
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box
          style={{
            background: "#F2F7FF",
            borderRadius: 20,
            padding: 30,
            marginTop: 30,
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <img
              src={
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU"
              }
              alt=""
              width="40px"
              style={{ borderRadius: 50 }}
            />
            <Box style={{ marginLeft: 15 }}>
              <Typography style={{ fontWeight: "bold", fontSize: "12px" }}>
                @{name}
              </Typography>
              <Typography style={{ fontSize: "10px" }}>
                {10} Million Followers
              </Typography>
            </Box>
          </Box>
          <Typography style={{ margin: "10px 0px" }}>
            Live life on your own terms #jointhemvmt Watches, Eyewear & Premium
            Accessori es For her @mvmtforher Shop our Site
          </Typography>

          <Typography style={{ fontWeight: "bold" }}>
            {" "}
            #Live #life #jointhemvmt #Watches #Eyewear #Premium #Accessori es{" "}
          </Typography>
        </Box>
        <AccountAnalytics
          avglikes={likes}
          avgcomments={comments}
          er={engagement}
          Id={id}
          selectedSocial={selectedSocial}
        />
        {/* <Box style={{ background: '#F2F7FF', borderRadius: 20, padding: 30, marginTop: 30, }}>

                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: 15 }} >Account Analytics</Typography>
                        <Button variant="contained" style={{ padding: '5px 20px', borderRadius: 30, fontSize: '10px', color: "#FFFFFF", fontWeight: 'bold', background: '#3DBE72', boxShadow: 'none' }}>View Full Report</Button>
                    </Box>


                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15 }}>
                        <Box style={{ width: '48%', background: '#FFFFFF', padding: 15, borderRadius: 10, }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>ER</Typography>
                                <Box>
                                    <span style={{ fontSize: 8 }}>Average</span>
                                    <IconButton style={{ color: '#3DBE72' }}>

                                        <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Typography style={{ fontWeight: 'bold', fontSize: '1.7rem' }} >0.12%</Typography>
                        </Box>

                        <Box style={{ width: '48%', background: '#FFFFFF', padding: 15, borderRadius: 10 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Suggested Price</Typography>
                                <IconButton style={{ color: '#3DBE72' }}>
                                    <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
                                </IconButton>
                            </Box>
                            <Typography style={{ fontWeight: 'bold', fontSize: '1.7rem' }} >$1.5K - $3k</Typography>
                        </Box>
                    </Box>



                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15 }}>
                        <Box style={{ width: '48%', background: '#FFFFFF', padding: 15, borderRadius: 10, }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Average Likes</Typography>
                                <IconButton style={{ color: '#3DBE72' }}>
                                    <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
                                </IconButton>
                            </Box>
                            <Typography style={{ fontWeight: 'bold', fontSize: '1.7rem' }} >266</Typography>
                        </Box>

                        <Box style={{ width: '48%', background: '#FFFFFF', padding: 15, borderRadius: 10 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Average Comments</Typography>
                                <IconButton style={{ color: '#3DBE72' }}>
                                    <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
                                </IconButton>
                            </Box>
                            <Typography style={{ fontWeight: 'bold', fontSize: '1.7rem' }} >6</Typography>
                        </Box>
                    </Box>




                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15 }}>
                        <Box style={{ width: '48%', background: '#FFFFFF', padding: 15, borderRadius: 10, }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Brand Mentions</Typography>
                                <IconButton style={{ color: '#3DBE72' }}>
                                    <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
                                </IconButton>
                            </Box>
                            <Typography > <span style={{ fontWeight: 'bold', fontSize: '1.7rem' }}>O</span> <span>in last 180d</span></Typography>
                        </Box>

                        <Box style={{ width: '48%', background: '#FFFFFF', padding: 15, borderRadius: 10 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Yearly Growth</Typography>
                                <Box>
                                    <span style={{ fontSize: 8 }}>Low</span>
                                    <IconButton style={{ color: '#3DBE72' }}>
                                        <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Typography style={{ fontWeight: 'bold', fontSize: '1.7rem' }} >-0.8%</Typography>
                        </Box>
                    </Box>





                </Box>
 */}
      </Box>
    </>
  );
};

export default InfluencerProfile;
