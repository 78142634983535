import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  Icon,
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Stack,
  styled,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import AccordionActions from "@mui/material/AccordionActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { Box } from '@mui/system'
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import AccountAnalytics from "./AccountAnalytics";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Instagram, YouTube } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import OverViewChart from "./OverViewChart";
import ContentChart from "./ContentChart";
import AudienceChart from "./AudienceChart";
import LookalikeChart from "./LookalikeChart";
import CustomCarousel1 from "./CustomCarousel1";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import InfluencersDataTable from "./InfluencersDataTable";
import { getAllInfluencers } from "../../../../service/getAllApi";
import InfluencersDataTable1 from "./InfluencersDataTable1";
import { useSelector } from "react-redux";
import BrandMentions from "./BrandMentions";

const useStyles = makeStyles({
  accordionContainer: {
    margin: "10px 0",
    padding: 0,
    "&::before": {
      content: "none",
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AccordionOptionsInsta = [
  {
    name: "Overview",
    titles: [
      {
        name: "Engagement",
        options: [
          { name: "Engagement Rate", elementType: "text" },
          { name: "Average Number of Likes on Posts", elementType: "text" },
          { name: "Average Number of Views on Posts", elementType: "text" },
          { name: "Average Number of Comments", elementType: "text" },
          { name: "Average Posts Per Week", elementType: "text" },
          { name: "Average Posts Per Month", elementType: "text" },
        ],
      },
      {
        name: "Credibility",
        options: [
          {
            name: "Percentage of Channel Subscribers Credibility",
            elementType: "text",
          },
          { name: "Age Group of the Channel", elementType: "text" },
          { name: "Language of the Channel", elementType: "text" },
          { name: "Credibility of Channel Viewers", elementType: "text" },
          { name: "Channel Content Count", elementType: "text" },
          {
            name: "Performance of Sponsored Videos vs Organic Videos",
            elementType: "text",
          },
        ],
      },
      {
        name: "Location",
        options: [
          { name: "Profile Location Country", elementType: "text" },
          { name: "Profile Location State", elementType: "text" },
          { name: "Profile Location City", elementType: "text" },
        ],
      },
      {
        name: "Growth",
        options: [
          { name: "Followers Growth Rate", elementType: "fullgraph" },
          {
            name: "Brand Mentions (In the past 3 months)",
            elementType: "mention",
          },
        ],
      },
    ],
  },
  {
    name: "Content",
    titles: [
      {
        name: "Top",
        options: [
          {
            name: "List of top hashtags used by the creator",
            elementType: "text",
          },
          {
            name: "List of top mentions used by the creator",
            elementType: "text",
          },
          { name: "List of top interests of the creator", elementType: "text" },
          { name: "Name of the creator brand affinity", elementType: "text" },
          { name: "", elementType: "carousel" },
        ],
      },
      {
        name: "Recent",
        options: [{ name: "", elementType: "carousel" }],
      },
      {
        name: "Sponsored",
        options: [{ name: "", elementType: "carousel" }],
      },
    ],
  },
  {
    name: "Audience",
    titles: [
      {
        name: "Followers",
        options: [
          { name: "Profile Audience Languages", elementType: "graph" },
          {
            name: "Profile Audience Languages Percentage",
            elementType: "graph",
          },
          { name: "Profile Audience Interests Name", elementType: "graph" },
          {
            name: "Profile Audience Interests Percentage",
            elementType: "graph",
          },
          {
            name: "Profile Audience Follower Types Name",
            elementType: "graph",
          },
          {
            name: "Profile Audience Follower Types Percentage",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Countries",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Countries Percentage",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Cities Name",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Cities Percentage",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Gender Age Distribution - Gender",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Gender Age Distribution - Age Range",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Gender Age Distribution Percentage",
            elementType: "graph",
          },
          { name: "Profile Audience Lookalikes", elementType: "table" },
          { name: "Profile Significant Followers", elementType: "table" },
          { name: "Profile Audience Credibility Score", elementType: "graph" },
          {
            name: "Profile Significant Followers Percentage",
            elementType: "graph",
          },
        ],
      },
      {
        name: "Likers",
        options: [
          { name: "Profile Audience Likers", elementType: "graph" },
          { name: "Profile Audience Likers Countries", elementType: "graph" },
          {
            name: "Profile Audience Likers Countries Percentage",
            elementType: "graph",
          },
          { name: "Profile Audience Likers Cities Name", elementType: "graph" },
          {
            name: "Profile Audience Likers Cities Percentage",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Gender Age Distribution - Gender",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Gender Age Distribution - Age Range",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Gender Age Distribution Percentage",
            elementType: "graph",
          },
          { name: "Profile Audience Likers Languages", elementType: "graph" },
          {
            name: "Profile Audience Likers Languages Percentage",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Interests Name",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Interests Value",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Followers Types Name",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Followers Types Percentage",
            elementType: "graph",
          },
          { name: "Profile Audience Likers Lookalikes", elementType: "table" },
          {
            name: "Profile Audience Likers Significant Followers",
            elementType: "table",
          },
          {
            name: "Profile Audience Likers Credibility Score",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Significant Followers Percentage",
            elementType: "graph",
          },
        ],
      },
    ],
  },
  {
    name: "Lookalike",
    titles: [
      {
        name: "Profile Lookalikes",
        options: [{ name: "", elementType: "table" }],
      },
    ],
  },
];

const AccordionOptionsYoutube = [
  {
    name: "Overview",
    titles: [
      {
        name: "Engagement",
        options: [
          { name: "Videos Engagement Rate", elementType: "text", key: "Engagement Rate" },
          { name: "Shorts Engagement Rate", elementType: "text", key: "Shorts Engagement Rate" },
          { name: "Average Number of Views on Videos", elementType: "text", key: "Average Number of Views on Videos" },
          { name: "Average Number of Views on Shorts", elementType: "text", key: "Average Number of Views on Shorts" },
          { name: "Average Number of Comments", elementType: "text", key: "Average Number of Comments" },
          { name: "Average Posts Per Week", elementType: "text", key: "Average Posts Per Week" },
          { name: "Average Posts Per Month", elementType: "text", key: "Average Posts Per Month" },
        ],
      },
      {
        name: "Credibility",
        options: [
          {
            name: "Percentage of Channel Subscribers Credibility",
            elementType: "text", key: "Percentage of Channel Subscribers Credibility"
          },
          { name: "Age Group of the Channel", elementType: "text", key: "Age Group of the Channel" },
          { name: "Language of the Channel", elementType: "text", key: "Language of the Channel" },
          { name: "Credibility of Channel Viewers", elementType: "text", key: "Channel Audience Viewers Cedibility Score" },
          { name: "Channel Content Count", elementType: "text", key: "Channel content count" },
          {
            name: "Performance of Sponsored Videos vs Organic Videos",
            elementType: "text", key: "Performance of sponsored videos vs organic videos"
          },
        ],
      },
      {
        name: "Location",
        options: [
          { name: "Channel Location Country", elementType: "text", key: "Channel Location Country" },
          { name: "Channel Location State", elementType: "text", key: "Channel Location State" },
          { name: "Channel Location City", elementType: "text", key: "Channel Location City" },
        ],
      },
      {
        name: "Growth",
        options: [
          { name: "Subscribers Growth Rate", elementType: "fullgraph" },
          { name: "Channel Views Growth Rate", elementType: "fullgraph" },
          {
            name: "Brand Mentions (In the Past 3 Months)",
            elementType: "mention",
          },
        ],
      },
    ],
  },
  {
    name: "Content",
    titles: [
      {
        name: "Top",
        options: [
          {
            name: "List of top hashtags used by the creator",
            elementType: "text", key: "List of top hashtags used by the creator"
          },
          {
            name: "List of top mentions used by the creator",
            elementType: "text", key: "List of top mentions used by the creator"
          },
          { name: "List of top interests of the creator", elementType: "text", key: "List of top interests of the creator" },
          { name: "Name of the creator brand affinity", elementType: "text", key: "Name of the creator brand affinity" },
          { name: "Channel Top Contents Type", elementType: "text", key: "" },
          { name: "Channel Top Contents URL", elementType: "text", key: "" },
          { name: "Channel Top Contents Description", elementType: "text", key: "" },
          { name: "Channel Top Contents Thumbnail URL", elementType: "text", key: "" },
          {
            name: "Channel Top Contents Engagement Views Count",
            elementType: "text", key: ""
          },
          {
            name: "Channel Top Contents Engagement Like Count",
            elementType: "text", key: ""
          },
          {
            name: "Channel Top Contents Engagement Comment Count",
            elementType: "text", key: ""
          },
          // { name: "", elementType: "carousel" },
        ],
      },
      {
        name: "Recent",
        options: [{ name: "", elementType: "carousel" }],
      },
      {
        name: "Sponsored",
        options: [{ name: "", elementType: "carousel" }],
      },
    ],
  },
  {
    name: "Audience",
    titles: [
      {
        name: "Followers",
        options: [
          { name: "Channel Audience Languages", elementType: "graph", key: "Channel Audience Languages" },
          {
            name: "Channel Audience Languages Percentage",
            elementType: "graph",
          },
          { name: "Channel Audience Interests Name", elementType: "graph" },
          {
            name: "Channel Audience Interests Percentage",
            elementType: "graph",
          },
          {
            name: "Channel Audience Subscribers Types Name",
            elementType: "graph",
          },
          {
            name: "Channel Audience Subscribers Types Percentage",
            elementType: "graph",
          },
          {
            name: "Channel Audience Subscribers Countries",
            elementType: "graph",
          },
          {
            name: "Channel Audience Subscribers Countries Percentage",
            elementType: "graph",
          },
          {
            name: "Channel Audience Subscribers Cities Name",
            elementType: "graph",
          },
          {
            name: "Channel Audience Subscribers Cities Percentage",
            elementType: "graph",
          },
          {
            name: "Channel Audience Subscribers Gender Age Distribution - Gender",
            elementType: "graph",
          },
          {
            name: "Channel Audience Subscribers Gender Age Distribution - Age Range",
            elementType: "graph",
          },
          {
            name: "Channel Audience Subscribers Gender Age Distribution Percentage",
            elementType: "graph",
          },
          { name: "Channel Audience Credibility Score", elementType: "text" },
          { name: "Channel Audience Lookalikes", elementType: "table" },
          { name: "Channel Significant Followers", elementType: "table" },
        ],
      },
      {
        name: "Likers",
        options: [
          { name: "Channel Audience Likers", elementType: "graph" },
          { name: "Channel Audience Likers Countries", elementType: "graph" },
          {
            name: "Channel Audience Likers Countries Percentage",
            elementType: "graph",
          },
          { name: "Channel Audience Likers Cities Name", elementType: "graph" },
          {
            name: "Channel Audience Likers Cities Percentage",
            elementType: "graph",
          },
          {
            name: "Channel Audience Likers Gender Age Distribution - Gender",
            elementType: "graph",
          },
          {
            name: "Channel Audience Likers Gender Age Distribution - Age Range",
            elementType: "graph",
          },
          {
            name: "Channel Audience Likers Gender Age Distribution Percentage",
            elementType: "graph",
          },
          { name: "Channel Audience Likers Languages", elementType: "graph" },
          {
            name: "Channel Audience Likers Languages Percentage",
            elementType: "graph",
          },
          {
            name: "Channel Audience Likers Interests Name",
            elementType: "graph",
          },
          {
            name: "Channel Audience Likers Interests Value",
            elementType: "graph",
          },
          {
            name: "Channel Audience Likers Followers Types Name",
            elementType: "graph",
          },
          {
            name: "Channel Audience Likers Followers Types Percentage",
            elementType: "graph",
          },
          {
            name: "Channel Audience Likers Credibility Score",
            elementType: "graph",
          },
          { name: "Channel Audience Likers Lookalikes", elementType: "table" },
          {
            name: "Channel Audience Likers Significant Followers",
            elementType: "table",
          },
        ],
      },
      {
        name: "Viewers",
        options: [
          { name: "Channel Audience Viewers", elementType: "graph" },
          { name: "Channel Audience Viewers Countries", elementType: "graph" },
          {
            name: "Channel Audience Viewers Countries Percentage",
            elementType: "graph",
          },
          {
            name: "Channel Audience Viewers Cities Name",
            elementType: "graph",
          },
          {
            name: "Channel Audience Viewers Cities Percentage",
            elementType: "graph",
          },
          {
            name: "Channel Audience Viewers Gender Age Distribution - Gender",
            elementType: "graph",
          },
          {
            name: "Channel Audience Viewers Gender Age Distribution - Age Range",
            elementType: "graph",
          },
          {
            name: "Channel Audience Viewers Gender Age Distribution Percentage",
            elementType: "graph",
          },
          { name: "Channel Audience Viewers Languages", elementType: "graph" },
          {
            name: "Channel Audience Viewers Languages Percentage",
            elementType: "graph",
          },
          {
            name: "Channel Audience Viewers Interests Name",
            elementType: "graph",
          },
          {
            name: "Channel Audience Viewers Interests Value",
            elementType: "graph",
          },
          {
            name: "Channel Audience Viewers Followers Types Name",
            elementType: "graph",
          },
          {
            name: "Channel Audience Viewers Followers Types Percentage",
            elementType: "graph",
          },
          {
            name: "Channel Audience Viewers Lookalikes",
            elementType: "table",
          },
          {
            name: "Channel Audience Viewers Cedibility Score",
            elementType: "graph",
          },
        ],
      },
    ],
  },
  {
    name: "Lookalike",
    titles: [
      {
        name: "Channel Lookalikes",
        options: [{ name: "", elementType: "table" }],
      },
    ],
  },
];

const BasicTabs = ({ userdata }) => {
  const [value, setValue] = React.useState(0);
  const [social, setSocial] = React.useState("Youtube");
  const [currentTab, setCurrentTab] = useState(0);
  const [influencers, setInfluencers] = useState([]);
  const [fetchingInfluencer, setFetchingInfluencer] = useState(false);
  const [fetchingInfluencers, setFetchingInfluencers] = useState(false);

  const classes = useStyles();

  const handleSocialChange = (event, newValue) => {
    setSocial(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentTab(newValue);
  };

  const fetchMoreInfluencers = async () => {
    try {
      setFetchingInfluencers(true);
      const response = await getAllInfluencers();
      console.log("influencersrr ", response.users);
      if (response.users.length > 0) {
        setInfluencers(response.users);
      }
    } catch (error) {
      console.error("Error fetching influencers:", error);
    } finally {
      setFetchingInfluencers(false);
    }
  };

  useEffect(() => {
    fetchMoreInfluencers();
  }, []);

  // data for charts and progress bar
  const data2 = [
    { label: "Group A", value: 2400 },
    { label: "Group B", value: 4567 },
    { label: "Group C", value: 1398 },
    { label: "Group D", value: 9800 },
    { label: "Group E", value: 3908 },
    { label: "Group F", value: 4800 },
  ];

  // custom progress bar

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#e36e46" : "#308fe8",
    },
  }));

  function CustomizedProgressBars({ data }) {
    return (
      <Stack spacing={1} sx={{ flexGrow: 1, padding: "10px 20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>{data.label}</Typography>
          <Typography>{data.value / 100} %</Typography>
        </Box>
        <BorderLinearProgress variant="determinate" value={data.value / 100} />
      </Stack>
    );
  }

  const IsOpen = useSelector((state) => state.sharedState.isOpen);

  const renderComponentByType = (
    elementType,
    option,
    data2,
    influencers,
    currentTab,
    fetchingInfluencer,
    fetchMoreInfluencers,
    fetchingInfluencers
  ) => {
    switch (elementType) {
      case "text":
        return (
          <div
            style={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              height: "100%",
            }}
          >
            <Typography>{option.name}</Typography>
          </div>
        );

      case "graph":
        return (
          <div
            style={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              marginTop: 20,
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton style={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            {currentTab === 0 && <OverViewChart />}
            {currentTab === 1 && <ContentChart options={option.name} />}
            {currentTab === 2 && <AudienceChart options={option.name} />}
            {currentTab === 3 && <LookalikeChart />}
          </div>
        );

      case "table":
        return (
          <div
            style={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              marginTop: 20,
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", marginTop: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                  {option.name}
                </Typography>
                <IconButton style={{ color: "#3DBE72" }}>
                  <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
                </IconButton>
              </Box>
              <InfluencersDataTable1
                influencers={influencers}
                fetching={fetchingInfluencer}
                fetchMoreInfluencers={fetchMoreInfluencers}
                onScrollFetching={fetchingInfluencers}
              />
            </Box>
          </div>
        );

      case "fullgraph":
        return (
          <div
            style={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              marginTop: 20,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton style={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            {currentTab === 0 && <OverViewChart />}
          </div>
        );

      case "carousel":

        return (
          <Box
            sx={{
              maxWidth: IsOpen ? "85vw" : "71vw",
            }}
            style={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              marginTop: 20,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton style={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            {!IsOpen ? (
              <CustomCarousel1 value={5} />
            ) : (
              <CustomCarousel1 value={6} />
            )}
            {!IsOpen ? (
              <CustomCarousel1 value={5} />
            ) : (
              <CustomCarousel1 value={6} />
            )}
          </Box>
        );

      case "mention":
        return (
          <Box
            sx={{
              maxWidth: IsOpen ? "85vw" : "71vw",
            }}
            style={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              marginTop: 20,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton style={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            <BrandMentions />
          </Box>
        );

      default:
        return null;
    }
  };

  const renderYoutubeComponentByType = (
    elementType,
    option,
    data2,
    influencers,
    currentTab,
    fetchingInfluencer,
    fetchMoreInfluencers,
    fetchingInfluencers
  ) => {
    const commonStyle = {
      background: "#FFFFFF",
      padding: 15,
      borderRadius: 10,
      height: "100%",
      // marginTop: 20,
    };

    switch (elementType) {
      case "text":
        return (
          <div style={commonStyle}>
            <Typography>{option.name}</Typography>
            <Typography variant="h5">{userdata[`${option.key}`]}</Typography>
          </div>
        );

      case "graph":
        return (
          <div style={commonStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton style={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            {currentTab === 0 && <OverViewChart />}
            {currentTab === 1 && <ContentChart options={option.name} />}
            {currentTab === 2 && <AudienceChart options={option.name} />}
            {currentTab === 3 && <LookalikeChart />}
          </div>
        );

      case "fullgraph":
        return (
          <div style={{ ...commonStyle, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton style={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            {currentTab === 0 && <OverViewChart />}
            {/* Adjust for other fullgraph types if needed */}
          </div>
        );
      case "mention":
        return (
          <div style={{ ...commonStyle, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton style={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            <BrandMentions userdata={userdata} option={option} />
          </div>
        );

      case "carousel":
        return (
          <div style={{ ...commonStyle, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton style={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            <CustomCarousel1 />
          </div>
        );

      case "table":
        return (
          <div
            style={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              marginTop: 20,
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", marginTop: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                  {option.name}
                </Typography>
                <IconButton style={{ color: "#3DBE72" }}>
                  <HelpOutlineRoundedIcon style={{ fontSize: 15 }} />
                </IconButton>
              </Box>
            </Box>
            <InfluencersDataTable
              influencers={influencers}
              fetching={fetchingInfluencer}
              fetchMoreInfluencers={fetchMoreInfluencers}
              onScrollFetching={fetchingInfluencers}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        padding: 0,
      }}
    >
      <TabContext value={social}>
        <Box
          sx={{
            maxWidth: "100%",
            ml: 5,
          }}
        >
          <TabList
            onChange={handleSocialChange}
            aria-label="lab API tabs example"
          >
            {/* <Tab
              icon={
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png"
                  alt="Instagram"
                  width={20}
                />
              }
              iconPosition="start"
              sx={{ color: "black" }}
              label="Instagram"
              value="Instagram"
            /> */}
            <Tab
              icon={
                <img
                  src="https://cdn-icons-png.flaticon.com/512/1384/1384060.png"
                  alt="Youtube"
                  width={20}
                />
              }
              iconPosition="start"
              sx={{ color: "black" }}
              label="Youtube"
              value="Youtube"
            />
          </TabList>
        </Box>
        <TabPanel value="Instagram">
          <Box
            sx={{
              maxWidth: "100%",
              padding: 0,
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: 0,
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Overview"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Content"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Audience"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Lookalike"
                  {...a11yProps(0)}
                />
              </Tabs>
            </Box>

            {AccordionOptionsInsta.map((section, sectionIndex) => (
              <CustomTabPanel
                value={value}
                index={sectionIndex}
                key={sectionIndex}
              >
                {section.titles.map((title, titleIndex) => (
                  <div key={titleIndex} className={classes.accordionContainer}>
                    <Accordion
                      defaultExpanded
                      style={{
                        background: "#F2F7FF",
                        border: "0",
                        boxShadow: "0",
                        borderRadius: 20,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls={`panel-${titleIndex}-content`}
                        id={`panel-${titleIndex}-header`}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: { md: "center", xs: "flex-start" },
                            flexDirection: { xs: "column", md: "row" },
                          }}
                        >
                          <Typography
                            style={{ fontWeight: "bold", fontSize: 15 }}
                          >
                            {title.name}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* Grid components */}
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns:
                              "repeat(auto-fit, minmax(500px, 1fr))",
                            gap: 20,
                            marginBottom: 40,
                          }}
                        >
                          {title.options
                            .filter(
                              (option) =>
                                option.elementType === "graph" ||
                                option.elementType === "text"
                            )
                            .map((option, optionIndex) => (
                              <div key={optionIndex}>
                                {renderComponentByType(
                                  option.elementType,
                                  option,
                                  data2,
                                  influencers,
                                  currentTab,
                                  fetchingInfluencer,
                                  fetchMoreInfluencers,
                                  fetchingInfluencers
                                )}
                              </div>
                            ))}
                        </div>

                        {/* Full-width components */}
                        {title.options
                          .filter(
                            (option) =>
                              option.elementType === "table" ||
                              option.elementType === "fullgraph" ||
                              option.elementType === "carousel" ||
                              option.elementType === "mention"
                          )
                          .map((option, optionIndex) => (
                            <div key={optionIndex} style={{ marginTop: 20 }}>
                              {renderComponentByType(
                                option.elementType,
                                option,
                                data2,
                                influencers,
                                currentTab,
                                fetchingInfluencer,
                                fetchMoreInfluencers,
                                fetchingInfluencers
                              )}
                            </div>
                          ))}

                        {title.name === "Content" && (
                          <Box sx={{ marginTop: 2, width: "100%" }}>
                            <CustomCarousel1 />
                          </Box>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </CustomTabPanel>
            ))}
          </Box>
        </TabPanel>
        <TabPanel value="Youtube">
          <Box
            sx={{
              maxWidth: "100%",
              padding: 0,
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: 0,
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Overview"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Content"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Audience"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Lookalike"
                  {...a11yProps(0)}
                />
              </Tabs>
            </Box>

            {AccordionOptionsYoutube.map((section, sectionIndex) => (
              <CustomTabPanel
                value={value}
                index={sectionIndex}
                key={sectionIndex}
              >
                {section.titles.map((title, titleIndex) => (
                  <div key={titleIndex} className={classes.accordionContainer}>
                    <Accordion
                      defaultExpanded
                      style={{
                        background: "#F2F7FF",
                        border: "0",
                        boxShadow: "0",
                        borderRadius: 20,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls={`panel-${titleIndex}-content`}
                        id={`panel-${titleIndex}-header`}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: { md: "center", xs: "flex-start" },
                            flexDirection: { xs: "column", md: "row" },
                          }}
                        >
                          <Typography
                            style={{ fontWeight: "bold", fontSize: 15 }}
                          >
                            {title.name}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          {/* Grid layout for other components */}
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns:
                                "repeat(auto-fit, minmax(500px, 1fr))",
                              gap: 20,
                            }}
                          >
                            {title.options
                              .filter(
                                (option) =>
                                  !["fullgraph", "carousel", "table"].includes(
                                    option.elementType
                                  )
                              )
                              .map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  style={{
                                    background: "#FFFFFF",
                                    padding: 15,
                                    borderRadius: 10,
                                  }}
                                >
                                  {renderYoutubeComponentByType(
                                    option.elementType,
                                    option,
                                    data2,
                                    influencers,
                                    currentTab,
                                    fetchingInfluencer,
                                    fetchMoreInfluencers,
                                    fetchingInfluencers
                                  )}
                                </div>
                              ))}
                          </div>

                          {/* Full-width components */}
                          {title.options
                            .filter((option) =>
                              ["fullgraph", "carousel", "table"].includes(
                                option.elementType
                              )
                            )
                            .map((option, optionIndex) => (
                              <div
                                key={optionIndex}
                                style={{ width: "100%", marginBottom: 20 }}
                              >
                                {renderYoutubeComponentByType(
                                  option.elementType,
                                  option,
                                  data2,
                                  influencers,
                                  currentTab,
                                  fetchingInfluencer,
                                  fetchMoreInfluencers,
                                  fetchingInfluencers
                                )}
                              </div>
                            ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </CustomTabPanel>
            ))}
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default BasicTabs;
