import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  Icon,
  IconButton,
  Button,
  styled,
  TextField,
  InputAdornment,
  Dialog,
} from "@mui/material";
import { useParams } from "react-router-dom";
// import { Box } from '@mui/system'
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountAnalytics from "./AccountAnalytics";
import DP from "../../../images/dp-lg.png";
import dp from "../../../images/dp.png";
import ShareIcon from "../../../images/directionRight.png";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Bio from "./Bio";
import BasicTabs from "./BasicTabs";
import { getUserByID } from "../../../../service/user/getUserByID";
import {
  AddRounded,
  Instagram,
  Search,
  ShareRounded,
  YouTube,
} from "@mui/icons-material";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
import Card4 from "./Card4";
import CustomCarousel from "./CustomCarousel";
import CustomCarousel1 from "./CustomCarousel1";
import ShareCollection from "../../collection/ShareCollection";
import ShareInfluencers from "./ShareInfluencers";
import ProfileCarousel from "./ProfileCarousel";
import { useSelector } from "react-redux";

const EditButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: "white",
  background: "black",
  fontSize: ".8rem",
  padding: "2px 5px",
  "&:hover": {
    color: "white",
    background: "black",
  },
}));

const Profile = (props) => {
  const [user, setUser] = useState({});

  const { id } = useParams();

  // Base64 decode function
  const decodeId = (encodedId) => atob(encodedId);
  const decodedId = decodeId(id);
  console.log("decodedId : ", decodedId);

  // const rowData = props.location?.state?.rowData || {};
  // console.log("profile ", rowData);

  const getUser = async () => {
    const user = await getUserByID({ id: decodedId });
    console.log("user from profile page : ", user);
    setUser(user);
    console.log("User state : ", user);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUser();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log("Search Term Updated:", event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const IsOpen = useSelector((state) => state.sharedState.isOpen);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const [shareCollectionDrawer, setShareCollectionDrawer] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          gap: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: "1.5rem",
            color: "#3dbf73ff",
            fontWeight: "bold",
            minWidth: "20%",
          }}
        >
          Find Influencers
        </Typography>
        {/* <TextField
          size="small"
          outline="false"
          placeholder="Search Influencers..."
          value={searchTerm} // Bind the searchTerm state to the TextField
          onChange={handleSearchChange} // Handle changes in the TextField
          sx={{
            width: "50%",
            borderRadius: 8,
            borderColor: "black",
            "& .MuiOutlinedInput-root": {
              borderRadius: 8,
              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}
        <Button
          sx={{
            fontWeight: "bold",
            color: "#3dbf73ff",
            borderRadius: 20,
            borderColor: "#3dbf73ff",
            textTransform: "none",
            "&:hover": {
              borderColor: "#3dbf73ff",
              borderWidth: "2px",
            },
            borderWidth: "2px",
            width: "40%",
          }}
          variant="outlined"
          startIcon={<AddRounded />}
        // onClick={handleOpen}
        >
          Add to campaign
        </Button>
        <Button
          sx={{
            fontWeight: "bold",
            color: "#3dbf73ff",
            borderRadius: 20,
            borderColor: "#3dbf73ff",
            textTransform: "none",
            "&:hover": {
              borderColor: "#3dbf73ff",
              borderWidth: "2px",
            },
            borderWidth: "2px",
            width: "40%",
          }}
          variant="outlined"
          startIcon={<AddRounded />}
        // onClick={handleOpen}
        >
          Add to collection
        </Button>
        <Button
          variant="contained"
          sx={{
            background: "#1f2933ff",
            fontWeight: "bold",
            borderRadius: 8,
            boxShadow: "none",
            width: "20%",
            "&:hover": {
              background: "#1f2933ff",
            },
          }}
        >
          Edit
        </Button>
        <ShareRounded
          onClick={handleClickOpen}
          sx={{
            color: "rgb(61, 191, 115)",
            ":hover": {
              cursor: "pointer",
            },
          }}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: { borderRadius: 5, border: "2px solid black" },
          }}
        >
          <ShareInfluencers sharePopUp={setOpen} />
        </Dialog>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "20%",
          }}
        >
          <Box
            sx={{
              overflow: "hidden",
              borderRadius: "100%",
              width: 150,
              height: 150,
            }}
          >
            {user.photo ? (
              <img
                src={user.photo}
                alt=""
                width="100%"
                height="100%"
                sx={{ objectFit: "cover", backgroundSize: "cover" }}
              />
            ) : (
              <img
                src={
                  "https://i.pinimg.com/736x/a2/11/7e/a2117e75dc55c149c2c68cbadee1f16e.jpg"
                }
                alt=""
                width="100%"
                height="100%"
                sx={{ objectFit: "cover", backgroundSize: "cover" }}
              />
            )}
          </Box>
        </Box>
        <Box sx={{ padding: 1, marginTop: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              {user.name}
            </Typography>
            <Box>
              <IconButton sx={{ color: "#E4405F" }}>
                {" "}
                {/* Instagram color */}
                <Instagram />
              </IconButton>
              <IconButton sx={{ color: "#FF0000" }}>
                {" "}
                {/* YouTube color */}
                <YouTube />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              marginTop: 1,
              gap: 3,
              columnGap: 10,
            }}
          >
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              Subscribers: {user.Subscribers ? user.Subscribers : 0}
              {/* Followers
                  Location
                  Account type of the profile, such as Personal, Creator or Business
                  Gender of the profile
                  Bio
                  Content Categories
                  Hashtags */}
            </Typography>
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              Location: {user.Location ? user.Location : "Location"}
            </Typography>
            {/* <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              Account: {user.AccountType ? user.AccountType : "Account"}
            </Typography> */}
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              Gender: {user["Influencer Gender"] ? user["Influencer Gender"] : ""}
            </Typography>
            {/* <Typography
                    sx={{
                      color: "black",
                      marginRight: "10px",
                    }}
                  >
                    {user.Bio ? user.Bio : "Bio"}
                  </Typography> */}
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              Category: {user["Influencer Category"] ? user["Influencer Category"] : ""}
            </Typography>
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              Tags: {user?.Hashtags?.split(", ")?.length > 0 ? user?.Hashtags?.split(", ").join(", ") : ""}
              <Box sx={{ display: "inline-flex", flexWrap: "wrap" }}>
                {user.Tags ? user.Tags : "Tags"}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#F2F7FF",
          borderRadius: 5,
          padding: 5,
          marginTop: 10,
          mx: 3,
        }}
      >
        <Typography sx={{ margin: "10px 0" }}>
          {user["Description (Hide contact details)"] ? user["Description (Hide contact details)"] : "Bio"}
        </Typography>

        <Typography sx={{ fontWeight: "bold" }}>
          {user["List of top hashtags used by the creator"] ? user["List of top hashtags used by the creator"] : ""}
        </Typography>
      </Box>
      {/* <Box
        sx={{
          backgroundColor: "#F2F7FF",
          borderRadius: 5,
          padding: 5,
          marginTop: 10,
          mx: "auto",
          overflow: "hidden",
          maxWidth: IsOpen ? "92vw" : "78vw",
          transition: "all 0.5s ease-in-out",
        }}
      >
        {!IsOpen ? (
          <ProfileCarousel
            value={
              screenWidth > 1442
                ? 5
                : screenWidth > 1024
                ? 5
                : screenWidth > 768
                ? 3
                : 1
            }
          />
        ) : (
          <ProfileCarousel value={5} />
        )}
      </Box> */}
      <Grid item md={12}>
        <BasicTabs userdata={user} />
      </Grid>
    </Box>
  );
};

export default Profile;
