import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Headers = [
  "Avg. Reach ",
  "Avg. ER ",
  "Total Reach",
  "Total Eng.",
  " Total",
];

const TotalSelectedInfluencers = ({ selectedRowArr }) => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "0.7rem 1rem",
        gap: "1rem",
      }}
    >
      {Headers.map((header, index) => (
        <Box key={index}>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "light",
                color: "gray",
                fontSize: "0.9rem",
              }}
            >
              {header}
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "0.9rem",
              }}
            >
              {selectedRowArr.length}
            </Typography>
          </Box>
          {/* <Box>{selectedRowArr.length}</Box> */}
        </Box>
      ))}
    </Box>
  );
};

export default TotalSelectedInfluencers;
